@import "@components/filter.scss";
@import "@components/paging.scss";
@import "@elements/select.scss";
@import 'keen-slider/keen-slider.scss';

.category-grid {
  grid-template-columns: 1fr 1fr;

  @media (min-width: $screen-medium) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

.intro {
  @media (max-width: $screen-small) {
    margin: 0 auto;
    text-align: center;
  }
}

.read-more {
  color: inherit;
  border-bottom: 2px solid;

  @media (max-width: $screen-medium) {
    display: block;
    width: fit-content;
    margin: 0 auto;
  }

  &:hover {
    text-decoration: none;
    color: var(--button-primary-background-color);
  }

  &:visited {
    color: inherit;
  }
}

.short-intro-text {
  margin: 0 0 20px;
  font-style: italic;

  @media (max-width: $screen-medium) {
      font-size: 14px;
  }
}
