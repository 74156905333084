﻿//override for breadcrumbs
main {
  > .breadcrumbs-container {
    z-index: 2;
    position: absolute;
    padding: var(--padding-xsmall) 0 0 var(--padding-small);
    margin: 0;

    .breadcrumbs {
      padding: 0;

      span {
        color: var(--color-white);
      }
    }

    @media (min-width: $screen-small) {
      padding: var(--padding-small) 0 0 var(--padding-small);
    }

    @media (min-width: $screen-medium) {
      padding: var(--padding-medium) 0 0 var(--padding-medium);
    }
  }
}

.brand-header {
  background-color: var(--color-grey-lighter);
  background-repeat: no-repeat;
  background-size: cover;
  padding: var(--padding-small);
  min-height: 300px;
  box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.2);
  display: flex;

  .intro {
    color: var(--color-white);

    > h1 {
      @include fluid-size(600, 1200, 34, 48);
      line-height: 100%;
      padding-top: var(--padding-xsmall);
    }
  }

  @media (min-width: $screen-small) {
    padding: calc(var(--padding-medium) + var(--padding-small)) var(--padding-small) var(--padding-small) var(--padding-small);
    align-items: flex-end;

    > .intro {
      > .intro-text {
        max-width: 60vw;
      }
    }
  }

  @media (max-width: $screen-small) {
    .intro {
      padding-top: var(--padding-small);


      .short-intro-text, .read-more {
        font-size: 14px;
      }

    }
  }

  @media (min-width: $screen-medium) {
    min-height: 450px;
    padding: calc(var(--padding-medium) + var(--padding-medium)) var(--padding-medium) var(--padding-medium) var(--padding-medium);

    > .intro {
      > .intro-text {
        max-width: 40vw;
      }
    }
  }
}

  .min-height-unset {
    min-height: unset !important;
  }

  .img-responsive {
    display: none;
    width: 100%;

    @media (min-width: $screen-xsmall) {
      display: block;
    }
  }
