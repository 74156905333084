﻿.product-page {
  .container {
    max-width: 100%;
    padding: 0;
    margin-bottom: 0;

    @media (min-width: $screen-medium) {
      max-width: var(--view-max);
      padding: 0 var(--padding-medium);
    }

    .eighteen-block {
      max-width: 1200px;
      display: grid;
      grid-template-columns: 50px auto;
      margin: var(--padding-small) auto;
      align-content: center;

      @media (max-width: $screen-medium) {
        background-color: white;
        margin: 0;
        padding: var(--padding-small);
      }

      span {
        margin: 0 0 0 15px;

        div {
          margin-left: 100px;
        }
      }
    }

    .p-main {
      display: grid;
      margin: 0 auto;
      position: relative;
      grid-gap: var(--padding-xsmall) var(--padding-xsmall);
      max-width: 100%;
      background-color: white;
      overflow: initial;
      grid-template-areas:
        'p-images' 'p-head' 'p-attributes-short' 'p-attributes-selector' 'p-buysection';
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;

      @media (min-width: $screen-medium) {
        grid-template-areas:
          'p-images' 'p-head' 'p-attributes-short' 'p-buysection';
        grid-gap: var(--padding-small) var(--padding-small);
        @include section-shadow();
        overflow: auto;
      }

      > div:not(.p-image-content):not(.p-attributes-short):not(.p-buysection) {
        padding: 0 var(--padding-small);

        @media (min-width: $screen-medium) {
          padding: 0;
        }
      }

      .p-image-content {
        grid-area: p-images;
        position: relative;
        background-color: var(--color-grey-lighter);
        border-radius: var(--element-radius);
        width: 100%;
        display: grid;
        place-content: center;
        align-self: auto;

        .p-image {
          display: grid;
          place-content: center;
          grid-auto-columns: auto;
          grid-template-rows: 1fr auto;
          max-width: var(--productpage-img-width);
        }

        .admin-editor-button[data-event="toggle-modal"] {
          top: 10px;
        }



        [data-productslider] {
          max-width: var(--productpage-img-width);
          aspect-ratio: 1 / 1;

          img {
            width: var(--productpage-img-width);
            height: var(--productpage-img-width);
            aspect-ratio: 1 / 1;
          }
        }

        [data-productslider-thumbs] {
          width: 100%;
          height: 68px;
          margin-bottom: 1rem;

          .keen-slider__slide.active {
            .image-content {
              background-color: #fff;
              box-shadow: 0 1.1rem 2rem rgba(0, 0, 0, 0.1);
            }
          }

          .image-content {
            transition: all 500ms ease-out;
            padding: 5px;
            height: 68px;
            display: grid;
            place-items: center;
            background-color: lighten($clr-grey-medium, 20%);
            border-radius: 6px;
          }
        }

        picture {
          display: block;
        }

        .hidden {
          display: none;
        }

        .p-flag {
          position: absolute;
          bottom: 1rem;
          left: 1rem;

          > span {
            height: 28px;
            width: auto;
            padding: 0.3rem 1rem;
            border-radius: 100px;

            > span {
              border: none;
              width: auto;
              height: auto;
            }
          }
        }
      }

      .p-description-short {
        grid-area: p-description-short;
      }
    }

    .p-info {
      position: relative;
      display: block;
      background-color: white;
      padding: var(--padding-small);

      @media (min-width: $screen-medium) {
        margin: var(--padding-small) auto;
        @include section-shadow();

        .p-info-section:first-child .p-info-title {
          border-top: none;
        }
      }

      .p-info-section:last-child {
        border-bottom: 1px solid var(--color-grey-light);
      }

      .p-info-title {
        font-family: 'Montserrat';
        font-size: 18px;
        font-weight: 600;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid var(--color-grey-light);
        padding: var(--padding-small) var(--padding-small) 0 0;
        pointer-events: none;


        i {
          pointer-events: none;
          transition: all 0.5s ease;
        }
      }

      .p-info-content {
        padding: var(--padding-xsmall);
        visibility: hidden;
        transform: translateY(-10px);
        opacity: 0;
        max-height: 0;
        transition: all 0.5s ease;

        .container {
          padding: 10px 0px;
        }
      }

      .p-info-section.open {
        .p-info-content {
          visibility: visible;
          transform: translateY(0);
          opacity: 1;
          max-height: 2000px;
        }

        i {
          transform: rotate(180deg);
        }

        a {
          font-weight: bold;
          text-decoration: underline;
        }

        a:hover {
          color: var(--color-blue-dark);
        }
      }
    }

    .p-head {
      grid-area: p-head;

      .p-head-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .p-brand {
        font-weight: 500;
        color: var(--color-grey-medium);
        @include fluid-size(600, 1200, 16, 18);
      }

      .p-title {
        @include fluid-size(600, 1200, 20, 28);
      }

      .p-rating {
        border-bottom: 1px solid #000;
        display: flex;

        div {
          pointer-events: none;
        }
      }

      .p-read-more {
        font-size: 12px;
        line-height: 16px;
        text-decoration: underline;
        font-weight: 500;
        margin-top: -5px;

        > a {
          color: #323232;
        }

        @media (min-width: $screen-medium) {
          margin-top: -10px;
        }
      }
    }

    .p-status {
      grid-area: p-status;
      @include place-self-start();
      width: 100%;

      .p-status-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .p-inventory {
          color: #74c69d;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;

          i {
            background-color: #74c69d;
            border-radius: 50%;
            height: 16px;
            width: 16px;
            background-size: 10px 10px;
          }
        }

        div.p-lob {
          color: var(--color-grey-medium);
        }
      }
    }

    .p-attributes-short {
      overflow: hidden;
      background-color: var(--productpage-attributes-background-color);
      color: white;
      grid-area: p-attributes-short;
      width: calc(100% - var(--padding-small) *2);
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      padding: var(--padding-xsmall) 0;


      @media (min-width: $screen-medium) {
        width: 100%;
        padding: var(--padding-xsmall) var(--padding-medium);
      }

      div {
        display: flex;
        align-items: center;
        flex-direction: column;

        > span {
          font-size: 14px;
          font-weight: 500;
          display: block;
          width: 100%;
        }

        > .p-attribute-value {
          font-size: 12px;
          font-weight: 400;
        }

        @media (min-width: $screen-medium) {
          > .p-attribute-key {
            font-size: 16px;
          }

          > .p-attribute-value {
            font-size: 14px;
          }
        }

        span.attr-strength {
          display: flex;
          align-items: center;
          height: 27px;

          > span {
            height: 20px;
            width: 20px;
            border: solid 1px var(--color-grey-medium);
            border-radius: 50%;
            display: inline-block;

            &:not(:last-child) {
              margin-right: 4px;
            }
          }

          &.attr-strength-10 {
            > span:first-child {
              background-color: var(--color-grey-medium);
            }
          }

          &.attr-strength-20 {
            > span:nth-child(-n + 2) {
              background-color: var(--color-grey-medium);
            }
          }

          &.attr-strength-30 {
            > span:nth-child(-n + 3) {
              background-color: var(--color-grey-medium);
            }
          }

          &.attr-strength-40 {
            > span:nth-child(-n + 4) {
              background-color: var(--color-grey-medium);
            }
          }

          &.attr-strength-50 {
            > span:nth-child(-n + 5) {
              background-color: var(--color-grey-medium);
            }
          }
        }
      }
    }

    .p-buysection {
      grid-area: p-buysection;
      width: 100%;

      @media (min-width: $screen-medium) {
        display: flex;
        align-items: flex-end;
      }

      .p-prices-buy-promo-content {
        display: flex;
        flex-direction: column;
        align-items: first baseline;
        pointer-events: none;
        margin-bottom: 1rem;

        .sales-price {
          margin-right: var(--padding-mini);
          font-size: 20px;
          font-weight: 800;
          line-height: 1;
          color: var(--color-black);
        }

        .recommended-price {
          text-decoration: line-through;
        }

        .unit-price {
          display: block;
          font-weight: 600;
          line-height: 1;
          font-size: 18px;
        }

        .p-buy-promo {
          position: relative;
          background-color: #000;
          padding: 0.3rem 0.5rem;
          color: #fff;
          border-radius: var(--element-radius);

          &:before {
            content: '';
            width: 0;
            height: 0;
            border-right: solid 1px transparent;
            border-right: solid 12px transparent;
            border-top: solid 12px #000;
            position: absolute;
            bottom: -12px;
            left: 10%;
            z-index: 3;
          }
        }
      }

      .p-buy-actions {
        display: grid;
        grid-template-rows: minmax(50px, auto);
        grid-auto-flow: row;
        width: 100%;
        gap: 0 var(--padding-small);

        .variant-select-content::-webkit-scrollbar {
          width: 3px;
        }

        .variant-select-content::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: var(--color-grey-lighter);
        }

        .variant-select-content::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: var(--color-grey-medium);
        }

        > div.variant-select-content {
          display: grid;
          grid-template-columns: 1fr;
          grid-column-gap: 6px;
          grid-row-gap: 6px;
          padding: 0 var(--padding-small) var(--padding-xsmall);
          overflow: initial;
          max-height: 100%;
          position: relative;

          @media (min-width: $screen-medium) {
            max-height: 310px;
            overflow-y: auto;
          }

          .dd-item {
            padding: var(--padding-xsmall) 17px;
            white-space: nowrap;
            background-color: #FAFAFA;
            border: 1px solid #E4E4E4;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            gap: 40px;
            align-items: center;
            font-weight: 500;
            position: relative;

            &[data-instock='False'] {
              color: #c2c2c2;
              pointer-events: none;

              .v-pack-price, .v-per-stuck {
                color: #c2c2c2;
              }

              .best-can-price-flag {
                display: none;
              }

              &.dd-selected .quantity-radio {
                background: #F3F3F3;
                border: 2px solid #E7E7E7;

                i {
                  display: none;
                }
              }
            }

            .best-can-price-flag {
              position: absolute;
              left: 0px;
              top: 0px;
              background: #7BA0C4;
              color: white;
              border-radius: 9px 0px 19px;
              padding: 1px 9px 1px 5px;
              font-family: Roboto;
              font-family: Roboto;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0em;
              text-align: center;
            }

            .v-quantity {
              font-size: 18px;
              flex-grow: 1;
              text-align: left;
            }

            .v-pack-price, .v-per-stuck {
              margin: 0;
            }

            .v-per-stuck {
              font-weight: 400;
              font-size: 14px;
              color: var(--color-grey-medium);
            }

            .v-pack-price {
              font-size: 16px;
              color: black;
              font-weight: 500;
            }

            .quantity-radio {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 42px;
              height: 42px;
              background: #F3F3F3;
              border: 2px solid #E7E7E7;
              border-radius: 21px;

              i {
                display: none;
              }
            }

            > span {
              padding: 0 0.5rem;
              font-weight: 500;
            }

            > * {
              pointer-events: none;
            }

            &.dd-selected {
              .quantity-radio {
                background-color: var(--dark-b-lg-sec-20);
                border: none;
                color: var(--color-white);

                i {
                  display: inline-block;
                  height: 18px;
                  width: 18px;
                  margin: auto;
                }
              }
            }
          }

          .dd-item:not([data-instock='False']):has(span.best-can-price-flag) {
            div {
              font-weight: bold;

              p {
                font-weight: bold;
              }
            }
          }

          > div {
            padding: 0.5rem;
            background-color: var(--color-grey-lighter);
            border-radius: 5px;
            display: grid;
            place-content: center;
            text-align: center;
            cursor: pointer;

            &:hover {
              background-color: darken($clr-grey-lighter, 10%);
            }
          }
        }

        > .add-to-bag-container {
          display: flex;
          margin: 10px 0 0;
          justify-content: space-between;
          align-items: center;
          box-shadow: 0 -5px 14px rgb(0 0 0 / 20%);
          bottom: 0;
          z-index: 10;
          position: sticky;
          background-color: white;
          flex-wrap: wrap;

          @media (min-width: $screen-medium) {
            margin: 0;
            border-top: 2px solid var(--color-grey-lighter);
            box-shadow: none;
            padding: 0;
          }

          .go-to-checkout {
            width: 100%;
            border-radius: 0;

            @media (min-width: $screen-medium) {
              margin: 10px 0 0;
            }

            i {
              margin: 0 10px 0 0;
            }
          }

          > .p-prices-buy-promo-content {
            margin: 0;
            width: 50%;
            padding: 0;

            @media (min-width: $screen-medium) {
              width: fit-content;
              flex-grow: 1;
              padding: 0 var(--padding-small) 0 0;
            }

            > span {
              padding: 2px 0;
              display: block;
              text-align: center;
              width: 100%;
            }
          }

          > button {
            flex-grow: 1;
            display: flex;
            justify-content: center;
            gap: 5px;
            max-width: 50%;
            border-radius: 0;

            @media (min-width: $screen-medium) {
              max-width: initial;
              font-size: 20px;
              min-width: 60%;
            }

            &[disabled=disabled] {
              background-color: var(--button-disabled-color);
              color: white;
            }
          }
        }

        .dropdown {
          width: 100%;
          height: var(--element-height-medium);

          .dd-menu {
            > .dd-item {
              > * {
                pointer-events: none;
              }
            }
          }
        }

        .button {
          padding: 1.8rem 1rem;
        }

        .single-price {
          background-color: #fff;
          height: var(--element-height-medium);
          width: 100%;
          padding: 0 var(--padding-small);
          display: inline-flex;
          align-items: center;
        }

        &.with-subscribe {
          display: flex;
          flex-wrap: wrap;

          .button {
            width: 50%;
          }

          .dropdown {
            flex: 0 0 100%;
          }
        }
      }
    }

    .p-description-full {
      grid-area: p-description-full;
    }

    .p-attributes-full {
      grid-area: p-attributes-full;
      @include place-self-start();
      width: 100%;

      p {
        font-size: 18px;
      }

      table {
        tr {
          td {
            padding: var(--padding-small) 0;
          }
        }
      }
    }

    .p-attributes-selector {
      @media (max-width: $screen-medium) {
        grid-area: p-attributes-selector;
      }

      @media(min-width: $screen-medium) {
        grid-column: 1 / -1;

        > div {
          display: flex;
          flex-direction: column;
          gap: 10px;

          > div {
            width: 50%
          }
        }
      }

      .attr-selector-nav {
        @media(min-width: $screen-medium) {
          max-width: 100%;
        }

        &.dd-menu {
          display: none;

          &.open {
            display: block;
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            background: white;
            z-index: 100;
            border: 1px solid #7BA0C4;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            margin-top: -2px;
          }

          .attr-option {
            margin: 5px 5px;
          }
        }
      }

      .attr-selector-container {
        cursor: pointer;
        position: relative;

        @media(max-width: $screen-medium) {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }



        .attr-selector {
          height: 40px;
          border: 1px solid #7BA0C4;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .attr-value {
          width: 100%;
          text-align: center;
          padding-left: 16px;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
        }

        i {
          margin-right: 10px;

          &.icon-arrow-down {
            display: none;
          }

          @media(min-width: $screen-medium) {
            display: none;

            &.open {
              display: inherit;
            }
          }
        }

        .attr-option {
          height: 42px;
          border: 1px solid #7BA0C4;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 10px 5px;

          a {
            font-weight: 500;
            font-size: 14px;
            line-height: 42px;
            text-decoration: none;
            width: 100%;
            height: 100%;
            text-align: center;
          }

          &.selected {
            background: #FD495C;
            border-color: #FD495C;

            span {
              color: white;
              font-weight: 500;
            }
          }

          &.disabled {
            background: #F3F3F3;
            border-color: #F3F3F3;

            a {
              color: #C2C2C2;
            }
          }
        }

        .nav-menu-content {
          left: auto;
          right: 0;

          ul {
            overflow-y: auto;
          }

          @media(min-width: $screen-medium) {
            width: 100%;
            padding: 0px;

            ul {
              max-height: 300px;
            }
          }

          .header-content {
            display: none;

            @media(max-width: $screen-medium) {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-weight: 600;
              font-size: 20px;
              line-height: 16px;
              margin-bottom: 30px;
              word-break: break-all;

              i {
                margin-right: 0px;
                color: #989796;
              }
            }
          }
        }
      }
    }

    #reviews .p-reviews {
      grid-area: p-reviews;
      @include place-self-start();
      width: 100%;
      padding: var(--padding-xsmall) 0;

      > div {
        grid-gap: 10px;
      }

      .review-importent-info {
        font-size: 14px;
      }

      .review-sum {
        color: var(--color-yellow);
        display: flex;

        i {
          margin-left: 2px;
        }
      }

      p {
        margin: 0;
        padding: 0 0 0 5px;
      }

      .button {
        width: fit-content;
      }

      .p-rating {
        display: flex;
      }

      .p-rating, .p-rating-card {
        span {
          width: 10px;
          height: 10px;
        }

        .review-count {
          height: auto;
        }
      }

      .review-item {
        background-color: var(--color-grey-lighter);
        padding: var(--padding-small);
        grid-row-gap: var(--gap-column-mini);
        border-radius: 2px;
      }

      .review-form-container {
        background-color: var(--color-grey-lighter);
        border-radius: var(--element-radius);
        padding: var(--padding-small) var(--padding-xsmall);

        form > div {
          display: flex;

          > div {
            margin-left: 10px;
          }
        }

        button {
          margin: 0 0 0 auto;
        }

        .rating-change {
          cursor: pointer;
          border-color: var(--color-grey-light);
          background-color: var(--color-grey-light);
          width: 20px;
          height: 20px;

          &.icon-circle-full {
            background-color: var(--color-green);
            border-color: var(--color-green);
          }
        }

        label, input, textarea {
          font-family: 'Montserrat';
          font-size: 14px;
        }

        .input {
          @media (min-width: $screen-medium) {
            background-color: white;
            border: none;
          }
        }
      }

      div[data-container='review-form'] {
        display: none;
      }
    }

    .p-usp-container {
      background-color: white;
      padding: var(--padding-small);
      padding-bottom: 2px;
      max-width: 1200px;

      .p-usp {
        width: max-content;
        margin: auto;

        @media (min-width: $screen-small) {
          width: 100%;
          display: flex;
          justify-content: space-around;

          > p {
            margin-bottom: 0px;
          }
        }

        > p {
          display: flex;
          align-items: center;
          gap: 8px;
          line-height: 16px;
          font-family: Montserrat;
          font-size: 14px;
          color: #777777;

          > i {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .p-short-desc::before {
      display: block;
      content: " ";
      margin-top: -100px;
      height: 100px;
      visibility: hidden;
      pointer-events: none;
    }

    .short-desc-container {
      background-color: white;
      padding: var(--padding-small);
      padding-bottom: 2px;
      max-width: 1200px;

      .short-desc {
        @media (max-width: $screen-medium) {
          border-top: 1px solid var(--color-grey-light);
          padding: var(--padding-small) var(--padding-xsmall) 0px;
        }

        font-weight: 600;
        line-height: 18px;
      }
    }
  }

  .star-ratings {
    height: auto;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;

    &-bottom {
      padding: 0;
      z-index: 0;
      display: flex;
      align-items: center;

      &.rate-one {
        > span {
          @include svg-transform($clr-black, $icon-star);
        }
      }

      &.rate-two {
        > span:nth-child(-n + 2) {
          @include svg-transform($clr-black, $icon-star);
        }
      }

      &.rate-tree {
        > span:nth-child(-n + 3) {
          @include svg-transform($clr-black, $icon-star);
        }
      }

      &.rate-four {
        > span:nth-child(-n + 4) {
          @include svg-transform($clr-black, $icon-star);
        }
      }

      &.rate-five {
        > span {
          @include svg-transform($clr-black, $icon-star);
        }
      }

      > span {
        @include svg-transform($clr-black, $icon-star-outline);
        height: 10px;
        width: 10px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
      }
    }
  }

  .review-count {
    font-size: 10px;
    font-family: "Montserrat";
    font-weight: 500;
    padding: 0 0 0 3px;
  }

  .p-rating-card {
    .star-ratings-bottom {
      > span {
        @include svg-transform(#ccc, $icon-star);
      }

      &.rate-one {
        > span:nth-child(-n + 1) {
          @include svg-transform($clr-blue, $icon-star);
        }
      }

      &.rate-two {
        > span:nth-child(-n + 2) {
          @include svg-transform($clr-blue, $icon-star);
        }
      }

      &.rate-tree {
        > span:nth-child(-n + 3) {
          @include svg-transform($clr-blue, $icon-star);
        }
      }

      &.rate-four {
        > span:nth-child(-n + 4) {
          @include svg-transform($clr-blue, $icon-star);
        }
      }

      &.rate-five {
        > span {
          @include svg-transform($clr-blue, $icon-star);
        }
      }
    }
  }

  .p-attribute {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    > p {
      margin: 0;
      padding: 0;

      &:first-child {
        padding-right: var(--padding-xsmall);
      }
    }
  }

  .product-admin-info {
    @include section-shadow();

    * {
      font-size: 1rem;
    }

    .nav-sync {
      padding: 0 0 10px 0;
      border-bottom: solid 3px #ddd;
      margin-bottom: 10px;

      .button-primary {
        box-shadow: none;

        > i {
          padding-right: 10px;
        }
      }
    }

    .admin-product-stats {
      position: relative;
      padding: 0;
      margin: 0;

      > div {
        padding: 5px 0;
        border-bottom: solid 1px #ddd;

        &:nth-child(1),
        &:nth-child(2) {
          border-bottom: none;

          &:hover {
            background-color: none;
          }
        }

        &:hover {
          border-bottom: solid 1px dodgerblue;
        }

        &:last-child {
          padding: 0;
          border-bottom: none;
        }
      }
    }
  }

  @media (min-width: $screen-medium) {
    --productpage-img-width: 355px;

    .container {
      .p-main {
        max-width: 1200px;
        grid-template-areas:
          'p-images p-head' 'p-images p-buysection' 'p-attributes-short p-buysection';
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr;
        overflow: visible;
      }

      .p-info {
        max-width: 1200px;

        > .tabs {
          grid-area: p-info-col-1;
          height: 50px;

          li:last-child {
            display: none;
          }
        }

        > .tab-container {
          grid-area: p-info-content;
          @include section-shadow();
          border-radius: 0 0 6px 6px;
        }

        [data-id='product-facts'] {
          grid-area: p-info-col-2;
          display: block !important;
          @include section-shadow();
          padding: 0 var(--padding-small);

          > label {
            text-align: center;
            display: grid;
            place-content: center;
            height: 50px;
          }
        }
      }

      .p-buysection {
        .p-buy-actions {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;

          > .add-to-bag-container {
            grid-template-columns: 1fr minmax(100px, 4fr);
            grid-template-rows: 1fr;
          }
        }
      }

      .p-attributes-short {
        justify-content: space-between;
        align-items: center;

        div {
          flex-direction: column;
        }
      }

      .p-usp-container {
        margin: var(--padding-small) auto;
        @include section-shadow();
      }

      .short-desc-container {
        margin: var(--padding-small) auto;
        margin-top: 0px;
        @include section-shadow();

        .short-desc {
          font-size: 24px;
          font-weight: 500;
          line-height: 26px;
        }
      }
    }
  }

  .navigation-wrapper {
    position: static;

    .dots {
      grid-row-start: 2;
      grid-column-start: 1;
      grid-column-end: 4;
      display: flex;
      padding: 10px 0;
      justify-content: center;
    }

    .dot {
      border: none;
      width: 10px;
      height: 10px;
      background: #c5c5c5;
      border-radius: 50%;
      margin: 0 5px;
      padding: 5px;
      cursor: pointer;
    }

    .dot:focus {
      outline: none;
    }

    .dot--active {
      background: #000;
    }
  }

  .container-large {
    margin: 0 auto;
    max-width: var(--view-max);

    @media (min-width: $screen-medium) {
      margin: var(--padding-medium) auto;
      padding: 0 var(--padding-medium);
    }

    > div {
      background-color: white;
      padding: var(--padding-small) 5px 0;

      @media (min-width: $screen-medium) {
        @include section-shadow();
        max-width: 1200px;
        margin: 0 auto;
      }
    }

    h3 {
      text-align: center;
    }

    .keen-slider__slide {
      padding-bottom: var(--padding-small);

      .product-card-container {
        margin: 5px;
        box-shadow: -1px 0 5px rgb(0 0 0 / 20%);
      }
    }

    .navigation-wrapper {
      position: static;

      .arrow {
        display: none;
      }
      // scroll bar
      .dots {
        margin: 0 15px;

        .dot {
          width: 100%;
          border-radius: 0;
          margin: 0;
          padding: 0;
          height: 4px;
          background-color: var(--color-grey-lighter);
        }

        .dot:first-child {
          border-radius: 5px 0 0 5px;
        }

        @media (min-width: $screen-medium) {
          .dot:nth-last-child(4) {
            border-radius: 0 5px 5px 0;
          }

          .dot:nth-last-of-type(-n+3) {
            display: none;
          }
        }

        @media (min-width: $screen-xsmall) and (max-width: $screen-medium) {
          .dot:nth-last-child(3) {
            border-radius: 0 5px 5px 0;
          }

          .dot:nth-last-of-type(-n+2) {
            display: none;
          }
        }

        @media (max-width: $screen-xsmall) {
          .dot:nth-last-child(2) {
            border-radius: 0 5px 5px 0;
          }

          .dot:nth-last-of-type(-n+1) {
            display: none;
          }
        }



        .dot.dot--active {
          border-radius: 5px;
          background-color: var(--color-grey-mid);
        }
      }
    }
  }
}
